// import { v4 as uuidv4 } from "uuid";

const dataSlider = [
    {
        id: 0,
        title: "Lorem ipsum",
        subTitle: "Lorem"
    },
    {
        id: 1,
        title: "Lorem ipsum",
        subTitle: "Lorem"
    }
];

export default dataSlider;